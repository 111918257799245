import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

export default function Transparency() {
  return (
    <Layout>
      <Seo title="Transparency Policy" />
      <section className="wrapper">
        <div className="container py-8 md:py-16 max-w-3xl space-y-8">
          <h1>Transparency Policy</h1>
          <div className="space-y-2">
            <p>
              Building a home or renovating a property is no different. We know
              that the service we provide will ensure the best possible outcomes
              for our customers and represents exceptional value. Because of
              this, we are pleased to disclose our commission to any prospective
              clients. Builder Finders will gladly disclose the commission value
              of any project that we undertake. It’s an offer few builders for
              builder brokers will match, but one that we believe is essential
              to us developing partnerships based on trust.
            </p>
            <p>
              We believe that the best way to develop the level of trust,
              communication and respect needed in any successful partnership is
              through a policy of openness and honesty.
            </p>
            <p>
              We will be transparent about our services, options and applicable
              fees and charges and will communicate in simple to understand
              language and act with the highest standards of integrity.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}
